.progress-modal {
  &_body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 400px;
    height: 500px;
    background-color: white;
    outline: none;
    border-radius: 8px;
    padding: 16px;
  }
  &_content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    &_text {
      padding-top: 10px;
      flex: 1;
      font-size: 24px;
    }
    &_list {
      border-radius: 8px;
      flex: 10;
      overflow-y: scroll;
      scroll-behavior: smooth;
    }
  }
  &_item {
    border: 1px solid rgba(72, 72, 72, 0.09);
    padding: 15px 10px;
    border-radius: 8px;
    &_name {
      margin-bottom: 10px;
      margin-left: 5px;
    }
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
    &_message {
      margin-top: 10px;
      margin-left: 5px;
    }

  }
  &_img {
    width: 40px;
    height: 40px;
    margin-left: 20px;
    margin-top: 2px;
    margin-right: 20px;
    border-radius: 8px;
    padding: 5px;
    &:hover {
      background-color: rgba(72, 72, 72, 0.05);
    }
  }
}