.notifications-modal {
  position: absolute;
  z-index: 11;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  width: 350px;
  height: calc(100vh - 120px);
  left: 0;
  top: 75px;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  padding-left: 5px;
  padding-right: 3px;
  .absoluteCenter {
    width: 100%;
    height: 100%;
    background: lightgrey;
    opacity: 0.3;
    z-index: 33;
    .spinner {
      left: 50%;
    }
  }
  .btn {
    margin-top: auto;
  }

  .notification-item {
    position: relative;
    display: flex;
    cursor: pointer;
    background: #e5e5e5a3;
    // background: #4b9af54f;
    padding: 7px;
    border-radius: 4px;
    padding-right: 10px;
    opacity: 0.8;
    margin: 2px;
    margin-right: 10px;
    &.blue {
      background: white;
      opacity: 1;
      // border: 1px solid #4b9af54f;
      box-shadow: 0px 0px 2px 0px #4b9af54f;
      transition: background 150ms;
      &:hover {
        background: #4b9af521;
      }
    }
    .type {
      img {
        position: absolute;
        top: 35px;
        // bottom: 5%;
        right: 5%;
        margin-right: 15px;
      }
    }

    .notification-item-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-basis: 100%;
      width: 100%;
      min-height: 60px;
      word-break: break-word;

      .text {
        .title,
        .date,
        .id {
          margin: 1px 0 0px 0;
        }
        .title {
          font-size: 14px;
          line-height: 17px;
          padding-right: 5px;
        }
        .date,
        .id {
          font-size: 12px;
          line-height: 14px;
          color: #b9b9b9;
        }
      }

      img {
        opacity: 0.4;
      }
    }

    padding-bottom: 5px;
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 15px;
    &:last-child {
      border-bottom-color: transparent;
      margin-bottom: 0;
    }
  }
}
