.versionCheckBox {
  padding: 20px;
  border-radius: 15px;
  background: white;
  width: 500px;
  .iconBox {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .text {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 15px;
    font-family: "SF UI Display Regular", sans-serif;
    font-size: 20px;
  }
  .buttonBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .btn {
      max-width: 48%;
      &:hover {
        background: #4b9af5e8;
      }
    }
    .btn.danger {
      height: 50px;
      margin: 0;
      &:hover {
        background: #fd3845e8;
      }
    }
  }
}

.currentAppVersion {
  position: fixed;
  bottom: 0;
  right: 0;
  color: grey;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 600;
  opacity: 0.4;
}
