.wrap_checkbox {
  display: flex;
  align-items: center;
  position: relative;
  .checkbox {
    z-index: -1;
  }
  .checkbox + label {
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: #272d2d;
    margin-left: 35px;
    &::before {
      content: "";
      position: absolute;
      width: 26px;
      height: 26px;
      top: 0;
      left: 0;
      border: 1px solid #4b9bf5;
      box-sizing: border-box;
      border-radius: 50%;
    }
  }

  .checkbox:checked + label::before {
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    top: 0;
    left: 0;
    background: url("../../../../style/icons/MarkDoneBlue/mark.svg") no-repeat
      center;
    border: 0;
  }
}
