.newSubscriberFormWrapper {
  min-height: 330px;
  width: 100%;

  .formTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #272d2d;
    margin-top: 10px;
    margin-bottom: 25px;
    padding: 0 5px;
  }

  .newSubscriberForm {
    height: calc(100% - 60px);
    overflow: hidden;
    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 2px;
      background: unset;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      height: 10%;
      border-radius: 2px;
      background: #4b9bf5;
    }

    .toggleField {
      label[for="fillType1"] {
        pointer-events: none;
      }
    }
    .formContent {
      .absoluteCenter {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .fileUploader {
        margin-top: 30px !important;
      }

      .commonInfo {
      }

      .formField {
      }

      .dateOfBirth {
        position: relative;
        width: 100%;
        height: 80px;
        max-width: 320px;
        display: flex;
        align-items: center;

        .formField {
          width: 100%;

          .rdt {
            max-width: 100%;
            border-radius: 8px;
          }
        }

        img {
          width: 18px;
          height: 18px;
        }

        .closeImg,
        .calendarImg {
          position: absolute;
          top: 14px;
        }

        .closeImg {
          right: 40px;
        }

        .calendarImg {
          right: 10px;
        }
      }

      .addressWrapper {
        height: 80px;

        button {
          min-height: 50px;
          height: auto;

          span {
            display: block;
            padding: 0 5px;
          }
        }

        .blur {
          span {
            color: #b9b9b9;
          }
        }

        .optionSelectingContainer.show {
          .formTitle {
            img {
              cursor: pointer;
            }
          }
        }
      }
    }
    .formFooter {
      .btn {
        margin-top: 20px;
      }
    }
  }
}


.updateSubscriptionForm {
  height: calc(100% - 60px);
  overflow: hidden;
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 2px;
    background: unset;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    height: 10%;
    border-radius: 2px;
    background: #4b9bf5;
  }

  .toggleField {
    label[for="fillType1"] {
      pointer-events: none;
    }
  }
  .formFooter {
    .btn {
      margin-top: 20px;
    }
  }
}