@mixin change-login-box {
  max-width: max-content;
  font-size: 14px;
  padding: 8px 12px;
  background-color: #3488e9;
  border-radius: 8px;
  border: none;
  color: #272D2D;
  margin: 10px 0;
}

.change-login-modal {

  &_body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 400px;
    height: 300px;
    background-color: white;
    outline: none;
    border-radius: 8px;
  }
  &_scroll {
    max-height: 200px;
    overflow-y: scroll;
  }
  &_content {
    &_header {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #E7E7E7;
      & span {
        font-size: 24px;
      }
    }
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    &_body {
      padding: 16px;
      border-radius: 8px;
      flex: 10;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

  }
  &_current {
    display: flex;
    flex-direction: column;
    &_simple {
      padding-top: 10px;
    }
    &_warning {
    }
    &_text {
      margin: 10px 0;
    }
  }
  &_new {
    display: flex;
    flex-direction: column;
    &_title {
      @include change-login-box;
    }
  }
  &_control {
    display: flex;
  }
  &_button {
    &_success {
      @include change-login-box;
      background-color: #4B9BF5;
      margin-right: 10px;
      color: white;
      &:disabled {
        background-color: #4B9BF5CC;
      }
    }
    &_cancel {
      @include change-login-box;
      background-color: #FD3845;
      color: white;
    }
  }
}

