.favoritesItemsWrapper {
  height: 100%;
  width: 100%;
  .absoluteCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .titleWithFilters {
    display: flex;
    //margin-top: 20px;
    margin-bottom: 10px;
    align-items: center;
    .title {
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 38px;
      //margin-top: 25px;
      margin-right: 40px;
    }
    .count {
      border: 1px solid #bec0c0;
      font-size: 12px;
      border-radius: 15px;
      color: #bec0c0;
      height: 30px;
      display: flex;
      align-items: center;
      padding: 0 15px;
      margin-right: 10px;
      border: 1px solid #4b9bf5;
      background: #4b9bf5;
      color: white;
    }
  }
  .favoritesItemsContainer {
    height: calc(100% - 100px);
    overflow: auto;
    border-radius: 8px;
    scrollbar-width: none;
    // display: flex;
    // display: grid;
    // grid-template-columns: repeat(4, calc((100% / 4) - 10px));
    // grid-gap: 10px;
    // align-content: baseline;

    .itemContainer {
      // display: flex;
      // flex-direction: row;
      // justify-content: space-between;
      // align-items: center;
      display: grid;
      grid-template-columns: 70px 1fr 1fr;
      // text-align: center;
      background-color: #fff;
      // box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 17px 20px;
      height: 100px;
      cursor: pointer;
      margin: 3px;
      transition: all 100ms ease-in;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      &:hover,
      &:focus {
        -webkit-box-shadow: 0px 0px 4px 1px #4b9af57e;
        -moz-box-shadow: 0px 0px 4px 1px #4b9af57e;
        box-shadow: 0px 0px 4px 1px #4b9af57e;
      }

      .patientFavorite {
        width: 50px;
        height: 50px;
        border-radius: 8px;
      }
      .titleBox,
      .idBox {
        text-align: center;
      }
      .avatarBox {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .sectionTitle {
        font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        color: #b9b9b9;
      }

      .sectionText {
        font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 17px;
        text-align: center;
        color: #272d2d;
      }

      .patientFooter {
        margin-top: auto;
        width: 100%;
        height: 20px;
        display: flex;
        justify-content: space-between;

        .idBlock {
          font-family: "SF UI Display Medium", sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;

          .itemIdTitle {
            color: #b9b9b9;
            margin-right: 10px;
          }

          .itemIdValue {
            color: #272d2d;
          }
        }
      }
    }
  }
}
