.progress-bar {
  &__container {
    width: 100%;
    height: 35px;
    padding: 5px 5px;
    border-radius: 10px;
    background-color: #F6F7FB;
  }
  &__body {
    &_value {
      height: 25px;
      border-radius: 12px;
      min-width: 25px;
      background-color: #4B9BF5;
      &_active {
        transition: all 2.5s ease;
        animation: move 2.5s infinite linear;
      }
    }

  }
}

@keyframes move {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}